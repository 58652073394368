<template>
  <b-row>
    <b-col cols="12" v-if="can('read/laporan-uang-harian')">
      <b-card title="Laporan Uang Harian">
        <b-row class="pb-2">
          <b-col class="mb-1">
            <!-- <b-button
              v-if="can('filter/laporan-uang-harian')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-0"
              @click="show=true"
            >
              Filter
            </b-button> -->
            <b-button
              v-if="can('print/laporan-uang-harian')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class=""
              @click="exportData()"
            >
              Export Data
            </b-button>
          </b-col>

          <b-col md="4" xs="12">
            <b-form @submit.prevent="handleSearch">
              <b-input-group size="8">
                <b-form-input
                  id="filterInput"
                  v-model="search"
                  type="search"
                  :placeholder="'Cari disini...'"
                />
                <b-input-group-append>
                  <b-button :disabled="!search" @click="search = ''">
                    Hapus
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>

        <b-table
          striped
          hover
          responsive
          show-empty
          class="position-relative"
          primary-key="email"
          :per-page="perPage"
          :current-page="currentPage"
          :items="getItems"
          :fields="fields"
          ref="tableFe"
        >
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(name)="data">
            {{ data.item.nippos }} / {{ data.item.name }}
          </template>

          <template #cell(total_days)="data">
            {{ data.item.total_days + ' hari' }}
          </template>

          <template #cell(bsu)="data">
            {{ data.item.bsu | currency }}
          </template>

          <template #cell(total)="data">
            {{ data.item.total | currency }}
          </template>

          <!-- A virtual column -->
          <template #cell(Aksi)="data">
            <div style="min-width: 270px">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                v-b-popover.hover.bottom="'Detail'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-popover.hover.bottom="'Approve'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                v-b-popover.hover.bottom="'Ubah'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                v-b-popover.hover.bottom="'Hapus'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="confirmText(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>

        <span v-if="totalRows == 1" style="margin: 1rem"></span>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              @change="changePerPage()"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

            <!-- Modal -->
            <b-modal
              id="modal-filter"
              v-model="show"
              title="Filter"
              size="md"
              cancel-variant="outline-secondary"
            >
              <div class="hidden-scrollbar">
                <div class="inner">
                  <!-- <b-form
                ref="form"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <b-row
                  v-for="(item, index) in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <b-col md="4">
                    <b-form-group
                      label="Filter Berdasarkan"
                      label-for="name"
                    >
                      <b-input-group size="md">
                        <v-select v-model="model.key" :options="key_filter" :reduce="(item) => item.key" label="label" class="key-filter" />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group
                      label="Value"
                      label-for="zip-code"
                    >
                      <b-input-group size="md">
                        <b-form-select
                          v-model="nopend"
                          size="md"
                          class="w-25"
                          @change="changeCompany(nopend)"
                        >
                          <option
                              v-for="(i, index) in report"
                              :key="index"
                              :value="i.nopend"
                          >
                              {{ i.namaktr }}
                          </option>
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="1"
                    class="mr-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="success"
                      class="mt-2 btn-icon"
                      @click="repeateAgain"
                    >
                      <feather-icon
                        icon="PlusIcon"
                      />
                    </b-button>
                  </b-col>
                  <b-col
                    md="1" 
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="danger"
                      class="mt-2 btn-icon"
                      :disabled="index == 0"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form> -->
                  <b-form @submit.prevent="handleSubmit(filter)">
                    <b-row class="mb-1">
                      <b-col md="6">
                        <label>NIPPOS</label>
                        <v-select
                          v-model="model.nippos"
                          :options="report"
                          :reduce="item => item.nippos"
                          label="nippos"
                          class="key-filter"
                        />
                      </b-col>
                      <b-col md="6">
                        <label>No SPPD</label>
                        <v-select
                          v-model="model.sppd_number"
                          :options="report"
                          :reduce="item => item.sppd_number"
                          label="sppd_number"
                          class="key-filter"
                        />
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col md="6">
                        <label>Nama</label>
                        <v-select
                          v-model="model.name"
                          :options="report"
                          :reduce="item => item.name"
                          label="name"
                          class="key-filter"
                        />
                      </b-col>
                      <b-col md="6">
                        <label>Tanggal Berangkat</label>
                        <br />
                        <date-picker
                          :firstDayOfWeek="1"
                          v-model="model.departure_date"
                          name="departure_date"
                          :lang="lang"
                          :format="formats"
                          value-type="YYYY-MM-DD"
                          :disabled-date="disabledDate"
                        ></date-picker>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col md="6">
                        <label>Tanggal Kembali</label>
                        <br />
                        <date-picker
                          :firstDayOfWeek="1"
                          v-model="model.return_date"
                          name="return_date"
                          :lang="lang"
                          :format="formats"
                          value-type="YYYY-MM-DD"
                          :disabled-date="disabledDate"
                        ></date-picker>
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </div>
              <template #modal-footer>
                <div class="w-100">
                  <b-button
                    variant="outline-secondary"
                    size="md"
                    class="float-right"
                    @click="reset()"
                  >
                    Reset
                  </b-button>
                  <b-button
                    variant="primary"
                    size="md"
                    class="float-right btn btn-primary mr-1"
                    @click="filter()"
                  >
                    Submit
                  </b-button>
                </div>
              </template>
            </b-modal>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Tidak Memiliki Akses</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: 'red !important';
}
</style>

<script>
import {
  BForm,
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BButtonGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardBody,
  BFormGroup,
  BFormSelect,
  BPagination,
  VBPopover,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TableFe from '@/views/table/bs-table/TableFE'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'SppdList',
  components: {
    BForm,
    BRow,
    BCol,
    BTable,
    BCard,
    TableFe,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardBody,
    BFormGroup,
    BFormSelect,
    vSelect,
    BPagination,
    VBPopover,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      config: {
        api: '/report-moneys',
      },
      show: false,
      search: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 10,
      sortBy: 'id',
      pageOptions: [1, 10, 25, 50, 100],
      fields: [
        'no',
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'name', label: 'NIPPOS / Nama' },
        { key: 'position', label: 'Jabatan' },
        { key: 'destination_office', label: 'Kantor/Kota Tujuan' },
        { key: 'departure_date', label: 'Tanggal Berangkat' },
        { key: 'total_days', label: 'Total Hari' },
        { key: 'bsu', label: 'BSU Harian' },
        { key: 'total', label: 'Total' },
      ],
      items: [
        {
          id: 1,
          selected: 'male',
          selected1: 'designer',
          prevHeight: 0,
        },
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
      report: [],
      key_filter: [
        { key: 'nippos', label: 'NIPPOS' },
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'name', label: 'Nama' },
        { key: 'departure_date', label: 'Tanggal Berangkat' },
        { key: 'return_date', label: 'Tanggal Kembali' },
      ],
      model: {
        nippos: '',
        sppd_number: '',
        name: '',
        departure_date: '',
        return_date: '',
      },
      nextTodoId: 2,
      formats: 'DD/MM/YYYY',
      lang: {
        days: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        pickers: [
          'next 7 days',
          'next 30 days',
          'previous 7 days',
          'previous 30 days',
        ],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range',
        },
      },
    }
  },
  watch: {
    search: function () {
      this.$refs.tableFe.refresh()
    },
  },
  mounted() {
    let _ = this
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text,
        )
      }
    }
    _.getReports()
  },
  methods: {
    disabledDate(date) {
      return new Date() < date
    },
    getItems(ctx, callback) {
      const _ = this

      let endpoint = `${_.config.api}?per_page=${_.perPage}&page=${_.currentPage}`
      if (_.search) {
        endpoint += `&key=${_.search}`
      }
      if (_.model.nippos) {
        endpoint += `&nippos=${_.model.nippos}`
      }
      if (_.model.sppd_number) {
        endpoint += `&sppd_number=${_.model.sppd_number}`
      }
      if (_.model.name) {
        endpoint += `&name=${_.model.name}`
      }
      if (_.model.departure_date) {
        endpoint += `&departure_date=${_.model.departure_date}`
      }
      if (_.model.return_date) {
        endpoint += `&return_date=${_.model.return_date}`
      }

      axios
        .get(endpoint)
        .then(resp => {
          const consume = resp.data.data
          _.data = resp.data.data
          _.perPage = consume.per_page
          _.currentPage = consume.current_page
          _.totalRows = consume.total
          callback(consume.data || [])
        })
        .catch(err => {
          console.log(err)
        })
      return null
    },
    handleSearch() {
      this.$refs.tableFe.refresh()
    },
    changePerPage() {
      const _ = this
      _.$refs.tableFe.refresh()
    },
    changeCurrentPage(currentPage) {
      let _ = this
      _.currentPage = currentPage
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    exportData() {
      let _ = this
      let today = new Date()
      let dateNow = today
        .toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        })
        .replace(/[^\d,.-]/g, '-')
      let timeNow =
        today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds()
      let endpoint = `${_.config.api + '/export'}?key=${_.search}`
      if (_.model.nippos) {
        endpoint += `&nippos=${_.model.nippos}`
      }
      if (_.model.sppd_number) {
        endpoint += `&sppd_number=${_.model.sppd_number}`
      }
      if (_.model.name) {
        endpoint += `&name=${_.model.name}`
      }
      if (_.model.departure_date) {
        endpoint += `&departure_date=${_.model.departure_date}`
      }
      if (_.model.return_date) {
        endpoint += `&return_date=${_.model.return_date}`
      }
      axios({
        url: endpoint,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          'laporan-uang-harian' + dateNow + '-' + timeNow + '.xls',
        )
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    },
    getReports() {
      let _ = this
      let uri = '/misc/report'
      axios
        .get(uri + '?company=' + _.userData.nopend)
        .then(resp => {
          _.report = resp.data.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    filter() {
      this.show = false
      this.$refs.tableFe.refresh()
    },
    reset() {
      this.show = false
      ;(this.model.nippos = ''),
        (this.model.sppd_number = ''),
        (this.model.name = ''),
        (this.model.departure_date = ''),
        (this.model.return_date = ''),
        this.$refs.tableFe.refresh()
    },
  },
}
</script>

<style>
.b-popover {
  font-size: 10px;
}
</style>
